{
  "exemple_i18n": "Example ",
  "fermer_le_menu": "Close the menu",
  "la": "The",
  "maniere_la_plus_rapide_pour_trouver_des_films_gratuits": "the fastest way to find free movies!",
  "films_gratis_definition": "Films Gratis is a directory of download and streaming sites: it allows you to find out which sites and platforms you can watch your movies for free.",
  "copyright": "© Copyright 2023. All rights reserved",
  "films_gratis": "Films Gratis",
  "toggle_menu": "toggle menu",
  "contact": "Contact",
  "accueil": "Home",
  "a_propos": "About",
  "envoyer": "Send",
  "message": "Message",
  "ton_adresse_mail": "Your email address",
  "john_doe": "John Doe",
  "ton_nom": "Your name",
  "contacte_nous": "Contact us!",
  "une_liste_actualisee": "An updated list based on the openings and closures of various sites that allow you to watch and download free movies.",
  "requetes_d_ajout_ou_de_suppression_de_plateformes_suggestion": "Requests for adding or removing platforms, suggestions, a little message... you can tell us everything!",
  "les_sites_de_telechargement_direct": "Direct download sites",
  "en_savoir_plus": "Learn more",
  "suggestion_de_site": "Site suggestion",
  "objet_du_message": "Subject of the message",
  "moteur_de_recherche": "Search engine",
  "classement": "Ranking",
  "comparateur": "Comparator",
  "type_de_site": "Type of site",
  "recherche": "Search..."
}
